.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.company {
  &:hover {
    background-color: 'blue';
  }
}


@keyframes flipIn {
  from {
    transform: perspective(800px) rotate3d(0, 1, 0, 90deg) scale(1);
    opacity: 0;
  }

  to {
    transform: perspective(800px);
  }
}

.matchup {
  height: 500px;
  background-color: white;
  background-size: cover;
  box-shadow: 0px 15px 12px 0px rgba(0,0,0,0.22), 0px 19px 38px 0px rgba(0,0,0,0.30);
  transition: transform 0.3s cubic-bezier(.17,.67,.17,1.27);
  perspective: 800;
  position: relative;
}

.flipping {
	animation: flipIn 0.5s cubic-bezier(.17,.67,.17,1.27);
}

  
@keyframes comengo {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.bubble-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.bubble {
  width: 3em;
  height: 3em;
  background-color: #6ED66E;
  border-radius: 100%;
  color: #fff;
  font-size: 3em;
  text-align: center;
  line-height: 128px;
  border: 8px solid lightgray;
  z-index: 999;
  transform: scale(0);
  animation: comengo 0.5s cubic-bezier(.05,1.07,.32,1.21) 0s 2 alternate forwards running;
}
